@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,400&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* @import url('https://fonts.googleapis.com/css?family=Montserrat:100,400&display=swap'); */

h1, h2, h3, h4, h5, p, span {
    font-family: 'Montserrat', sans-serif;
}

.big_triangle_home, .big_triangle_home_p2 {
    height: 20px;
}

.big_triangle_home:before, .big_triangle_home_p2:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home_p2:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #0a0a0a;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}


.big_triangle_home_p2:after, .big_triangle_home_p2:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #fff;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}

.btn-style-dark-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #151515;
    opacity: 0.6;
    border-color: none;
    border: 0;
    color:#FFFFFF;
}

.btn-style-dark-gray:hover{
    background-color: #3b3b3b;
    outline: none;
    border: 0;
}

.btn-style-light-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #FFFFFF;
    opacity: 0.6;
    border-color: none;
    border: 0;
}

.btn-style-light-gray:hover{
    color:#FFFFFF;
    background-color:rgb(144, 144, 144);
    outline: none;
    border: 0;
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}
.modal-header {
    border-bottom: none !important;
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}

.footer-menu{
    color: #ffffff;
    font-weight: 600;
}

.footer-menu:hover{
    color: #8dadc3;
    font-weight: 600;
}

.error {
    border: 2px solid #FF6565;
  }

  .error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
  }

  .btn-submit{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #151515;
    opacity: 0.8;
    border-color: none;
    border: 0;
    color:#FFFFFF;
  }
  
  .btn-submit:hover{
    background-color: #313131;
    outline: none;
    border: 0;
  }

  .btn-submit:disabled{
    background-color: #4e4e4e;
    outline: none;
    border: 0;
    cursor: not-allowed;
  }
  
  .modal-content {
    border-radius: 0px !important;
  }
.big_triangle {
    height: 20px;
}

.big_triangle:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle:after, .big_triangle:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #FFF;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

.image-energy-bg {
    background-image:url("/assets/img/icons/bg-energy-family.jpg");
    background-repeat:no-repeat;
    background-position:center top;
    background-size:cover;
    background-attachment:scroll;
}

.w30{
    width:30px;
}

.linheight30{
    line-height: 30px;
}

.f-w-s1-we300{
    color: #FFFFFF;
    font-size: 1.2m;
    font-weight: 300;
}

.btn-style-light-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #FFFFFF;
    opacity: 0.6;
    border-color: none;
    border: 0;
}

.btn-style-light-gray:hover{
    color:#FFFFFF;
    background-color:rgb(144, 144, 144);
    outline: none;
    border: 0;
}

.btn-style-dark-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #151515;
    opacity: 0.6;
    border-color: none;
    border: 0;
    color:#FFFFFF;
}

.btn-style-dark-gray:hover{
    background-color: #3b3b3b;
    outline: none;
    border: 0;
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}
.big_triangle {
    height: 20px;
}

.big_triangle:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle:after, .big_triangle:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #FFF;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

.image-energy-bg {
    background-image:url("/assets/img/icons/bg-energy-family.jpg");
    background-repeat:no-repeat;
    background-position:center top;
    background-size:cover;
    background-attachment:scroll;
}

.btn-style-dark-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #151515;
    opacity: 0.6;
    border-color: none;
    border: 0;
    color:#FFFFFF;
}

.btn-style-dark-gray:hover{
    background-color: #3b3b3b;
    outline: none;
    border: 0;
}

.btn-style-light-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #FFFFFF;
    opacity: 0.6;
    border-color: none;
    border: 0;
}

.btn-style-light-gray:hover{
    color:#FFFFFF;
    background-color:rgb(144, 144, 144);
    outline: none;
    border: 0;
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}

.big_triangle {
    height: 20px;
}

.big_triangle:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle:after, .big_triangle:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #FFF;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

.image-energy-bg {
    background-image:url("/assets/img/icons/bg-energy-family.jpg");
    background-repeat:no-repeat;
    background-position:center top;
    background-size:cover;
    background-attachment:scroll;
}

.gray-gradient-inclined-vertical{
    background: rgb(17,17,18);
    background: linear-gradient(153deg, rgba(17,17,18,1) 0%, rgba(91,91,97,1) 50%, rgba(17,17,17,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111112",endColorstr="#111111",GradientType=1);
}

.btn-style-dark-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #151515;
    opacity: 0.6;
    border-color: none;
    border: 0;
    color:#FFFFFF;
}

.btn-style-dark-gray:hover{
    background-color: #3b3b3b;
    outline: none;
    border: 0;
}

.btn-style-light-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #FFFFFF;
    opacity: 0.6;
    border-color: none;
    border: 0;
}

.btn-style-light-gray:hover{
    color:#FFFFFF;
    background-color:rgb(144, 144, 144);
    outline: none;
    border: 0;
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}

#us-map{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  path:hover, circle:hover {
    stroke: #002868 !important;
    stroke-width:2px;
    stroke-linejoin: round;
    fill: #002868 !important;
    cursor: pointer;
  }
  #path67 {
    fill: none !important;
    stroke: #A9A9A9 !important;
    cursor: default;
  }
  #info-box {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-color: #ffffff;
    border: 2px solid #002868;
    border-radius: 5px;
    padding: 5px;
    font-family: arial;
  }
  
.big_triangle {
    height: 20px;
}

.big_triangle:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle:after, .big_triangle:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #FFF;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

.image-energy-bg {
    background-image:url("/assets/img/icons/bg-energy-family.jpg");
    background-repeat:no-repeat;
    background-position:center top;
    background-size:cover;
    background-attachment:scroll;
}

.gray-gradient-inclined-vertical{
    background: rgb(17,17,18);
    background: linear-gradient(153deg, rgba(17,17,18,1) 0%, rgba(91,91,97,1) 50%, rgba(17,17,17,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111112",endColorstr="#111111",GradientType=1);
}

.btn-style-dark-gray{
  display: inline-block;
  padding: 0.7em 1.7em;
  margin: 0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 0px;
  outline: none;
  background-color: #151515;
  opacity: 0.6;
  border-color: none;
  border: 0;
  color:#FFFFFF;
}

.btn-style-dark-gray:hover{
  background-color: #3b3b3b;
  outline: none;
  border: 0;
}

.btn-style-light-gray{
  display: inline-block;
  padding: 0.7em 1.7em;
  margin: 0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 0px;
  outline: none;
  background-color: #FFFFFF;
  opacity: 0.6;
  border-color: none;
  border: 0;
}

.btn-style-light-gray:hover{
  color:#FFFFFF;
  background-color:rgb(144, 144, 144);
  outline: none;
  border: 0;
}

.div-padding {
  padding-top: 75px;
  padding-bottom: 75px;
}
.btn-submit{
  display: inline-block;
  padding: 0.7em 1.7em;
  margin: 0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 0px;
  outline: none;
  background-color: #151515;
  opacity: 0.8;
  border-color: none;
  border: 0;
  color:#FFFFFF;
}

.btn-submit:hover{
  background-color: #313131;
  outline: none;
  border: 0;
}

/* @import url('https://fonts.googleapis.com/css?family=Montserrat:100,400&display=swap'); */

h1, h2, h3, h4, h5, p, span {
    font-family: 'Montserrat', sans-serif;
}

.big_triangle_home, .big_triangle_home_p2 {
    height: 20px;
}

.big_triangle_home:before, .big_triangle_home_p2:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home_p2:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #0a0a0a;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}


.big_triangle_home_p2:after, .big_triangle_home_p2:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #fff;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}

.btn-style-dark-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #151515;
    opacity: 0.6;
    border-color: none;
    border: 0;
    color:#FFFFFF;
}

.btn-style-dark-gray:hover{
    background-color: #3b3b3b;
    outline: none;
    border: 0;
}

.btn-style-light-gray{
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0px;
    outline: none;
    background-color: #FFFFFF;
    opacity: 0.6;
    border-color: none;
    border: 0;
}

.btn-style-light-gray:hover{
    color:#FFFFFF;
    background-color:rgb(144, 144, 144);
    outline: none;
    border: 0;
}

.div-padding {
    padding-top: 75px;
    padding-bottom: 75px;
}

/* @import url('https://fonts.googleapis.com/css?family=Montserrat:100,400&display=swap'); */

h1, h2, h3, h4, h5, p, span {
    font-family: 'Montserrat', sans-serif;
}

.big_triangle_home, .big_triangle_home_p2 {
    height: 20px;
}

.big_triangle_home:before, .big_triangle_home_p2:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home_p2:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #0a0a0a;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}


.big_triangle_home_p2:after, .big_triangle_home_p2:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #fff;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}
/* @import url('https://fonts.googleapis.com/css?family=Montserrat:100,400&display=swap'); */

h1, h2, h3, h4, h5, p, span {
    font-family: 'Montserrat', sans-serif;
}

.big_triangle_home, .big_triangle_home_p2 {
    height: 20px;
}

.big_triangle_home:before, .big_triangle_home_p2:before {
    right: 50%;
    border-right: 1000px solid transparent;
    border-left: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home_p2:after {
    left: 50%;
    border-left: 1000px solid transparent;
    border-right: 1000px solid;
}

.big_triangle_home:after, .big_triangle_home:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #0a0a0a;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}


.big_triangle_home_p2:after, .big_triangle_home_p2:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 100;
    border-bottom: 40px solid #fff;
    -webkit-transform: rotate(0.000001deg);
    transform: rotate(0.000001deg);
}

.gray-gradient {
    background: rgba(254,254,254,1);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), color-stop(100%, rgba(226,226,226,1)));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254,254,254,1)), color-stop(63%, rgba(219,219,219,1)), color-stop(75%, rgba(219,219,219,1)), color-stop(98%, rgba(219,219,219,1)), to(rgba(226,226,226,1)));
    background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(219,219,219,1) 63%, rgba(219,219,219,1) 75%, rgba(219,219,219,1) 98%, rgba(226,226,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2e2e2', GradientType=0 );
}
.dropdown-toggle::after {
    display: block !important;
    color: transparent !important;
}

.ddl-menu {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    outline-color: transparent !important;
    box-shadow: none !important;
    border-radius: 300px !important;
}

.ddl-menu:hover{
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ddl-menu:focus{
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.icon-ddl-menu {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1em;
}
.dropdown-toggle::after {
    display: block !important;
    color: transparent !important;
}

.ddl-menu {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    outline-color: transparent !important;
    box-shadow: none !important;
    border-radius: 300px !important;
}

.ddl-menu:hover{
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ddl-menu:focus{
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.icon-ddl-menu {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1em;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.swal2-popup { 
  border-radius: 0px !important;
}
